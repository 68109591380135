@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.skin17 {
  padding: 12px 16px;
  min-height: 100%;
  background-color: #f4f5f7;
  line-height: normal;
  :global {
    .adm-step-content {
      margin-left: 8px;
    }
  }
  .topIcon{
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      color: #08979C;
      font-size: 18px;
      margin-right: 8px;
    }
    span {
      color: #262626;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .topCard{
    position: relative;
    padding: 16px 12px 60px 12px;
    width: 343px;
    // height: 165px;
    background-color: #F7707B;
    border-radius: 8px;
    overflow: hidden;
    .headerIcon{
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      .logo{
        height: 24px;
        width: 24px;
        color: #FFFFFF;
      }
      .textLogo{
        margin-left: 8px;
        color: #ffffff;
        height: 17px;
        width: 88px;
      }
    }
    .cardTitle{
      font-size: 12px;
      color: #FFFFFF;
    }
    .topCardBackgroundImg{
      position: absolute;
      right: 0;
      width: 111px;
    }
    .cardInputBox {
      margin-top: 12px;
      padding-bottom: 6px;
      position: relative;
      display: flex;
      align-items: center;
      height: 35px;
      border-bottom: 1px #ffffff solid;
      .inputBoxSymbol {
        color: #FFFFFF;
        font-size: 22px;
        font-weight: 600;
      }

      :global {
        .adm-input-element {
          height: 35px;
          color: #ffffff;
          font-size: 30px;
          font-weight: 600;
          line-height: 16px;
          &::placeholder {
            font-size: 18px;
            color: #ffffff;
            font-weight: 400;
          }
        }
      }

      .text {
        position: absolute;
        right: 0;
        bottom: 10px;
        display: flex;
        align-items: center;
        color: #ffffff;
        font-size: 12px;
        .closeIcon{
          font-size: 20px;
          margin-left: 10px;

          
        }
      }
    }
    .cardPromptText{
      margin-top: 6px;
      font-size: 12px;
      color: #ffffff;
    }
    .cardBottomBox{
      position: absolute;
      bottom: 0;
      left: 0;
      height: 52px;
      width: 100%;
      background-color: rgba($color: #ffffff, $alpha: 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .borrowAmount{
        width: 49%;
        color: #ffffff;
        padding: 8px 12px;
        span{
          font-size: 16px;
          margin-right: 2px;
        }
        .rightCircleIcon{
          font-size: 14px;
        }
      }
      .line{
        width: 1px;
        height: 22px;
        background-color: var(--color-primary);
      }
    }
  }
  .chooseTimeCard{
    margin-top: 12px;
    height: 86px;
    border-radius: 8px;
    background-color: #fff;
    padding: 12px;
    .chooseTimeTitle{
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #262626;
      .chooseTimeLeft{
        font-size: 14px;
        font-weight: 600;

      }
      .chooseTimeRight{
        font-size: 12px;
        span {
          font-weight: 600;
          color: var(--color-primary);
        }
      }
    }
    .loanTermList{
      margin-top: 8px;
      display: grid;
      grid-template-columns: repeat(4,1fr);
      grid-gap: 8px;
      .loanTermItem{
        height: 34px;
        border-radius: 4px;
        background-color: #F5F5F5;
        text-align: center;
        line-height: 34px;
        font-size: 13px;
        font-weight: 600;
      }
      .active{
        background-color: #fff;
        border: var(--color-primary) 1px solid;
        color: var(--color-primary);
      }
    }
  }
  .LoanDetailsCard{
    margin-top: 12px;
    padding: 12px 12px 0;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    .showDetailsBox{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .showDetailsMainText{
        font-size: 14px;
        font-weight: 600;
        color: #262626;
      }
      .showDetailsText{
        font-size: 12px;
        color: #8C8C8C;
        margin-left: 4px;
      }
      .shrinkBtn{
        color: var(--color-primary);
        font-size: 12px;
      }
    }
    .stepsBox{
      display: flex;
      margin-top: 4px;
      :global {
        .adm-steps-vertical .adm-step {
          height: 64px;
        }
        .adm-step-indicator{
          height: 58px;
        }
        .adm-steps-vertical{
          padding: 8px 16px 0;
        }
        .adm-steps-vertical .adm-step:last-child{
          height: 40px;
        }
        // .adm-step-indicator:last-child{
        //   height: 40px !important;
        // }
      }
      .listTimeBox{
        margin-top: 8px;
        width: 60px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        .numberCycle{
          width:60px;
          height: 64px;
          display: flex;
          flex-direction: column;
          align-items: end;
          color: #8C8C8C;
          font-size: 12px;
          .numberCycleTitle{
            margin-bottom: 4px;
            font-weight: 600;
            color: #262626;
          }
        }
      }
      .listTimePoint{
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border: 3px solid var(--color-primary);
      }
    }
  }

  .bottomBody {
    background-color: #fff;
    margin-top: 12px;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 8px;
    p {
      width: 100%;
      display: flex;
      justify-content: center;
      span {
        display: inline-block;
        color: #c16818;
        height: 41px;
        line-height: 41px;
        padding: 0 16px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("~@imgs/register/new-theme/bottom-body-bg.png");
      }
    }
    .phoneBox {
      height: 46px;
      background: #F5F5F5;
      border-radius: 24px;
      display: flex;
      align-items: center;
      margin: 9px auto 0;
      padding-left: 16px;
      .label {
        width: 170px;
        text-align: center;
        font-size: 16px;
      }
      :global {
        .adm-input-element {
          font-size: 16px;
          line-height: 30px;
          &::placeholder {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
    }
    .getCms {
      margin-top: 12px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-primary);
      border-radius:23px;
      color: #FFFFFF;
      font-size: 16px;
    }
    .agreement {
      @include flex-cc;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 12px;
      font-size: 12px;
      color: #8C8C8C;
      line-height: 18px;

      .primary {
        color: var(--color-primary);
      }
  


      &.animation {
        position: relative;
        animation: shake .1s;
      }

      @keyframes shake {
        0% {
          left: 8px;
        }

        50% {
          left: -8px;
        }
  
        100% {
          left: 0;
        }
      }
  
      .checked {
        margin-right: 6px;
        margin-bottom: 3px;
        border: 1px solid #D9D9D9;
        border-radius: 100%;
        width: 14px;
        height: 14px;
  
        &.active {
          position: relative;
          border: none;
          background: var(--color-primary);
  
          &::before {
            position: absolute;
            top: 3px;
            left: 2px;
            width: 10px;
            height: 7px;
            content: "";
            background-image: url("~@imgs/activity/check.png");
            background-size: 100%;
          }
        }
      }
    }
  }

  .tipBox {
    margin-top: 12px;
    padding-bottom: 50px;
    .tip {
      font-size: 10px;
      line-height: 20px;
      text-align: center;
      color: $text-gray-color;
    }
  }

  .dialogOverlay {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    &.showPopup {
      display: block;
    }
    .dialogMain {
      width: 320px;
      height: 180px;
      border-radius: 8px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px 0;
      box-sizing: border-box;
      .close {
        width: 100%;
        font-size: 20px;
        text-align: right;
        margin-right: 20px;
        color: rgb(214, 217, 223);
        padding-right: 20px;
        box-sizing: border-box;
      }
      .inputBox {
        width: 285px;
        height: 48px;
        border: 1px solid #e19459;
        margin: 10px auto;
        padding-left: 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        .code {
          width: 150px;
          text-align: center;
          color: #e19459;
        }
        :global {
          .adm-input-element {
            font-size: 15px;
            line-height: 30px;
          }
        }
      }
      .getQuota {
        margin: 18px auto;
        width: 283px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(180deg,#ffcfa0 1%,#e19459);
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
  }
}