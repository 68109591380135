@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.card {
  margin-bottom: 12px;
  border-radius: 8px;
  padding: 16px;
  background-color: $color-white;

  &:last-child {
    margin-bottom: 0;
  }

  .top {
    @include flex-sbc;

    .nameBox {
      @include flex-vc;

      .icon {
        overflow: hidden;
        border-radius: 2px;
        width: 20px;
        height: 20px;
      }

      .name {
        margin-left: 6px;
        width: 80px;
        font-size: 14px;
        font-weight: bold;

        @include ellipsis;
      }

      .tag {
        margin-left: 6px;
        border-radius: 2px;
        padding: 2px 6px;
        font-size: 12px;
        color: $color-primary;
        background: $light-red-bg;
      }
    }

    .numberOfApplicants {
      position: relative;
      top: 10px;
      font-size: 12px;
      color: $text-gray-color;
    }
  }

  .bottom {
    @include flex-sbc;

    margin-top: 4px;

    .left {
      display: flex;

      .limitBox {
        width: 116px;
      }

      .rateBox {
        width: 66px;
        text-align: center;
      }

      .limit,
      .interestRate {
        font-size: 24px;
        font-weight: bold;
        line-height: 36px;
        color: $color-primary;
      }

      .limitText {
        font-size: 12px;
        color: $text-gray-color;
      }

      .interestRate {
        // color: $color-primary;

        .unit {
          font-size: 16px;
          line-height: 30px;
        }
      }

      .interestRateText {
        // padding-right: 15px;
        color: $text-gray-color;
        font-size: 12px
      }
    }

    .apply {
      @include flex-cc;

      border-radius: 40px;
      width: 88px;
      height: 36px;
      font-size: 14px;

      // font-weight: 600;
      color: $color-white;
      background: $linear-gradient;
      mix-blend-mode: normal;
      line-height: 20px;

      &.disable {
        background: $border-color;
      }
    }
  }
}
