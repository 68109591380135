@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.skin18 {
  background-repeat: no-repeat;
  background-size: 100% 469px;
  background-image: url("~@imgs/register/background.png");
  .scrollcontainer {
    position: relative;
    top: 10px;
    left: 15px;
    width: 300px;
    height: 30px;
    overflow: hidden;
}
.scrollcontent {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color: #FFFFFF;
    animation: scrollUp 20s infinite;
    .scrollRow{
      display: flex;
      vertical-align: bottom;
      padding: 6px 0;
      height: 30px;
    }
}
@keyframes scrollUp {
    0% {
        transform: translateY(0);
    }
    10% {
      transform: translateY(-10%);
  }
    20% {
        transform: translateY(-20%);
    }
    30% {
      transform: translateY(-30%);
  }
    40% {
        transform: translateY(-40%);
    }
    50% {
      transform: translateY(-50%);
  }
    60% {
        transform: translateY(-60%);
    }
    70% {
      transform: translateY(-70%);
  }
    80% {
        transform: translateY(-80%);
    }
    90% {
      transform: translateY(-90%);
  }
    100% {
      transform: translateY(-90%);
    }
}
height: 100%;
background-color: #f4f5f7;
line-height: normal;
.selectBox{
  width: 200px;
  border: 0;
  :global {
    .selectBox__indicator-separator{
      display: none;
    }
    .selectBox__value-container--has-value{
      padding-right: 0;
    }
    .selectBox__indicators{
      padding: 0;
      width: 12px;
    }
    .selectBox__dropdown-indicator{
      padding: 0;
      width: 12px;
    }
    .selectBox__control--is-focused{
      border-color: #FFFFFF;
      box-shadow: none;
      border: 0;
    }
    .selectBox__menu{
      margin-top: -5px;
      box-shadow: rgba(0, 0, 0, 0.25);
      border: 0;
      position: absolute;
      width: 155%;
      left: -56%;
    }
    .selectBox__input-container{
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
    .selectBox__placeholder{
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      color: rgba(38, 38, 38, 1);
    }
    .selectBox__option {
      color: rgba(38, 38, 38, 1);
      background-color: #FFFFFF;
      text-align: right;
    }
    .selectBox__option--is-focused{
      color: rgba(38, 38, 38, 1);
      background-color: #FFFFFF;
    }
    .selectBox__option--is-selected{
      color: rgba(38, 38, 38, 1);
      background-color: #FFFFFF;
    }
  }
}
.moneyOptions {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .moneyItem {
    flex: 1;
    height: 25px;
    padding: 4px 8px;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 12px;
    background-color: #F5F5F5;
    color: #BFBFBF;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    font-weight: 400;
    &:nth-child(2n + 2) {
      margin: 0 8px;
    }
    &.moneyItemActive {
      color: #FFFFFF;
      border: 1px solid #FF2C3E;
      background-color: #FF2C3E;
    }
  }
}
.chooseBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  .chooseLeft{
    font-size: 14px;
    font-weight: 400;
    color: #262626;
  }
  .chooseRight{
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    span:nth-child(1) {
      font-size: 12px;
      color: #262626;
    }
    span:nth-child(2) {
      margin-top: 2px;
      font-size: 10px;
      color: #8C8C8C;
    }
  }
}
.header {
  margin: 16px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 12px;
  .inputWrapper{
    width: 319px;
    margin-top: 16px;
    color: #262626;
    .inputWrapperTitle{
      font-size: 14px;
      margin-bottom: 12px;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 19.6px;
      span:nth-child(2) {
        font-size: 12px;
      }
    }
    .inputBox{
      font-size: 30px;
      font-weight: 600;
      line-height: 42px;
      text-align: center;
    }
    .inputBoxText{
      font-size: 11px;
      font-weight: 400;
      line-height: 15.4px;
      text-align: center;
      color: #8C8C8C;
    }
    .chooseBox{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      .chooseLeft{
        font-size: 14px;
        font-weight: 600;
        color: #262626;
      }
      .chooseRight{
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
        span:nth-child(1) {
          font-size: 12px;
          color: #262626;
        }
        span:nth-child(2) {
          margin-top: 2px;
          font-size: 10px;
          color: #8C8C8C;
        }
      }
    }
  }
  .headerBottomText{
    margin-top: 20px;
    font-size: 9px;
    font-weight: 400;
    line-height: 12.6px;
    text-align: center;
    color: #8C8C8C;
  }
}
.messageBox{
  margin: 16px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 12px;
  text-align: center;
  .messageBoxTitle{
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    color: #262626;
  }
  .messageBoxText{
    padding: 5px 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #8C8C8C;
  }
  .messageBoxImgBox{
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    >div {
      >img{
        width: 32px;
        height: 32px;
      }
    }
  }
}
.getCode{
  width: 130px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  color:var(--color-primary);
}
.codeTime{
  width: 130px;
  padding-right: 10px;
font-size: 14px;
font-weight: 400;
line-height: 19.6px;
color: rgba(191, 191, 191, 1);
}

.checkBox {
  margin: 16px;
  padding: 12px 16px;
  background: #fff;
  border-radius: 8px;
  .title {
    width: 100%;
    height: 38px;
    background: linear-gradient(180deg, #FDF2E5 0%, #FBE4CE 100%);
    border-radius: 6px 6px 0px 0px;
    text-align: center;
    line-height: 38px;
    font-weight: 400;
    font-size: 12px;
    color: #DE9960;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    color: #333;
    padding: 12px 16px 0;
    span {
      font-size: 12px;
      color: #BB6C1D;
      font-weight: normal;
    }
  }

  .bottomBody {
    // margin-top: 20px;
    box-sizing: border-box;
    // padding: 0 16px;
    p {
      width: 100%;
      display: flex;
      justify-content: center;
      span {
        display: inline-block;
        color: #c16818;
        height: 41px;
        line-height: 41px;
        padding: 0 16px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("~@imgs/register/new-theme/bottom-body-bg.png");
      }
    }
    .phoneBox {
      height: 46px;
      background: #f4f5f7;
      border-radius: 8px;
      display: flex;
      align-items: center;
      margin: 9px auto 0;
      padding-left: 16px;
      .label {
        width: 170px;
        text-align: center;
        font-size: 16px;
      }
      :global {
        .adm-divider-vertical{
          color: black;
          border: 0.1px solid #D9D9D9;
          font-size: 16px;
          border-radius: 4px;
        }
        .adm-input-element {
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
          }
        }
      }
    }
    .getCms {
      margin-top: 12px;
      font-weight: 600;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-primary);
      border-radius: 999px;
      color: #FFFFFF;
      font-size: 16px;
    }
    .agreement {
      @include flex-cc;
      flex-wrap: wrap;

      .primary {
        color: var(--color-primary);
      }
  
      margin-top: 20px;
      font-size: 12px;
      color: #8C8C8C;
      line-height: 18px;

      &.animation {
        position: relative;
        animation: shake .1s;
      }

      @keyframes shake {
        0% {
          left: 8px;
        }

        50% {
          left: -8px;
        }
  
        100% {
          left: 0;
        }
      }
  
      .checked {
        margin-right: 6px;
        border: 1px solid #D9D9D9;
        border-radius: 100%;
        width: 16px;
        height: 16px;
  
        &.active {
          position: relative;
          border: none;
          background: var(--color-primary);
  
          &::before {
            position: absolute;
            top: 4px;
            left: 3px;
            width: 10px;
            height: 7px;
            content: "";
            background-image: url("~@imgs/activity/check.png");
            background-size: 100%;
          }
        }
      }
    }
  }
}

.tipBox {
  padding-bottom: 50px;
  .tip {
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: $text-gray-color;
  }
}
}

:global {
  .adm-auto-center {
  .adm-auto-center-content {
    text-align: center !important;
  }
}
}
