@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.mainLayout {
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    background: $color-white;
    box-shadow: 0 -4px 8px rgb(0 0 0 / 8%);

    :global {
      .adm-tab-bar-wrap {
        min-height: 52px;
      }

      .adm-tab-bar-item-active {
        color: $color-primary;
      }
    }
  }
}
