@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.fullPage {
  min-height: 100vh;
  background-color: #fff;
  padding-top: 48px;
  .noticeBar {
    padding: 5px 0;
    background-color: #FFF5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }
    span {
      color: #FF99A2;
      font-size: 13px;
    }
  }
  .from {
    margin-top: 61px;
    padding: 0 35px;
    .formItem {
      margin-bottom: 33px;
      .formItemTitle {
        font-size: 17px;
        font-weight: bold;
        color: #2B2929;
        margin-bottom: 15px;
      }
      .formItemInput {
        padding-bottom: 12px;
        border-bottom: 1px solid #E0E0E0;
        :global {
          .adm-input-element {
            font-size: 15px;
          }
        }
      }
    }
  }
  .footerBtn {
    width: 252px;
    height: 48px;
    margin: 150px auto 0;
    border-radius: 24px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: $color-white;
    line-height: 48px;
    background: $linear-gradient;
  }
}
