@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.fullPage {
  height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  background-color: $color-white;

  .header {
    padding: 20px 16px 10px;
    background-position: 121% top;
    background-repeat: no-repeat;
    background-size: 184px 184px;
    background-image: url("~@imgs/register/red-theme/fortune-bag.png");

    .logoBox {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;

      @include flex-cc;

      .logo {
        margin-right: 6px;
        width: 42px;
        height: 42px;
        background-size: 100%;
        background-image: url("~@imgs/register/red-theme/logo-big.png");
      }
    }

    .textTip {
      @include flex-vc;

      margin-top: 35px;
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 20px;
      color: $text-gray-color;

      .orange {
        font-weight: bold;
        color: #FE983E;
      }

      .percentage {
        display: inline-block;
        margin-left: 4px;
        width: calc(94px * .6);
        height: calc((31px * .6));
        background-size: 100%;
        background-image: url("~@imgs/register/red-theme/80percentage.png");
      }
    }

    .inputBox {
      position: relative;
      display: flex;
      align-items: baseline;
      border-bottom: 1px solid $border-color;

      :global {
        .adm-input-element {
          font-size: 32px;
          font-weight: 600;
          line-height: 45px;
        }
      }

      .text {
        position: absolute;
        right: 0;
        bottom: 10px;
        display: flex;
        align-items: center;
        color: $text-gray-color;

        .clear {
          padding-left: 6px;
        }
      }
    }

    .warmPrompt {
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-size: 12px;
      color: $text-gray-color;

      .tag {
        margin-right: 6px;
        border-radius: 4px 0;
        padding: 2px 8px;
        font-size: 12px;
        color: $color-white;
        background: linear-gradient(95.26deg, #FF8C98 0%, #FF0218 103.37%);
      }
    }
  }

  .line {
    height: 8px;
    background-color: #F0F0F0;
  }

  .periods {
    padding: 12px 16px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      border: 1px solid $border-color;
      border-radius: 6px;
      padding: 10px 12px;
      font-size: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .right {
        display: flex;
        align-items: center;

        .textBox {
          text-align: right;
        }

        .value {
          font-size: 15px;
          line-height: 21px;
        }

        .label {
          margin-top: 4px;
          font-size: 12px;
          font-weight: 400;
          color: $text-gray-color;
          line-height: 17px;
        }

        .select {
          position: relative;
          margin-left: 16px;
          border-radius: 100%;
          width: 18px;
          height: 18px;
          background-color: $border-color;

          &::before {
            position: absolute;
            top: 6px;
            left: 4px;
            width: 10px;
            height: 7px;
            content: "";
            background-image: url("~@imgs/activity/check.png");
            background-size: 100%;
          }
        }
      }

      &.active {
        border-color: $color-primary;
        background-color: #FFF1F0;

        .select {
          background: $color-primary;
        }
      }
    }

    .limitedTimeActivity {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;

      .left {
        width: 122px;
        height: 25px;
        background-size: 100%;
        background-image: url("~@imgs/register/red-theme/time-limit.png");
      }

      .tag {
        margin-left: 6px;
        border-radius: 4px;
        padding: 4px 6px;
        font-size: 12px;
        color: $color-white;
        background: #FF4D4F;
      }
    }
  }

  .formBox {
    padding: 16px;
    background-color: $color-white;
    .agreement {
      @include flex-cc;
  
      margin-top: 12px;
      font-size: 12px;
      color: #40404E;
  
      .checked {
        margin-right: 6px;
        border: 1px solid #D9D9D9;
        border-radius: 100%;
        width: 18px;
        height: 18px;
  
        &.active {
          position: relative;
          border: none;
          background: $linear-gradient;
  
          &::before {
            position: absolute;
            top: 6px;
            left: 4px;
            width: 10px;
            height: 7px;
            content: "";
            background-image: url("~@imgs/activity/check.png");
            background-size: 100%;
          }
        }
      }
    }
    .footerBtn {
      margin-top: 16px;
      border-radius: 24px;
      height: 48px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: $color-white;
      line-height: 48px;
      background: $linear-gradient;
    }
    .primary {
      color: $color-primary;
    }
  }
}
