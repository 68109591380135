@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.fullPage {
  background-color: #F5F6FA;
  padding: 48px 0;
  img {
    display: block;
  }
  .noticeBar {
    background-color: #FFF1F0;
    border: none;
    color: #FF4D4F;
  }
  .applyBox {
    .applyBoxImg {
      width: 100%;
      height: 100%;
    }
  }
  .applySteps {
    padding: 0 20px;
    .applyStepsImg {
      width: 100%;
      height: 100%;
    }
  }
  .aboutUs {
    margin-top: 24px;
    padding: 0 20px;
    .aboutUsTitle {
      font-size: 19px;
      color: #262626;
    }
    .aboutUsImg {
      margin-top: 12px;
      width: 100%;
      height: 100%;
    }
  }
  .tipBox {
    margin-top: 24px;
    padding-bottom: 50px;
    .tip {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: $text-gray-color;
    }
  }
}
