@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.retainPage {
  padding: 64px 20px;
  .productCard{
    position: relative;
    z-index: 1;
    padding: 16px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;
    .maskBox{
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      width: 343px;
      height: 294px;
      img {
        height: 105px;
        position: absolute;
        right: 10px;
        top: 0;
      }
    }
    .multipleProcessContentItem{
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      z-index: 1;
      .multipleProcessContentItemLeft{
        width: 90%;
        display: flex;
        justify-content: start;
        align-items: center;
        .contentItemIcon {
          width: 36px;
          height: 36px;
          margin-right: 10px;
          border-radius: 6px;
        }
        .multipleProcessContentItemInfo{
          span{
            font-size: 16px;
            color: #262626;
          }
          div {
            color: #8E8E8E;
            font-size: 12px;
          }
        }
        .multipleProductInfo{
          display: flex;
          flex-direction: column;
          justify-content: start;
          // align-items: center;
          .partnerCompanyName{
            color: #8E8E8E;
            font-size: 12px;
          }
          .multipleProductInfoTop{
            display: flex;
            justify-content: start;
            align-items: center;
            margin-bottom: 2px;
            .productName{
              font-weight: 700;
              font-size: 14px;
              color: #262626;
            }
            .platformName{
              text-align: center;
              margin-left: 10px;
              padding: 2px 4px;
              font-size: 12px;
              line-height: 1.3;
              color: #E4A26A;
              border: 1px solid #E4A26A;
              border-radius: 2px;
            }
          }
        }
      }
      .multipleProcessContentItemRight{
          :global {
            .adm-checkbox{
              --icon-size:14px
            }
            .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
              border-color:color-primary-fn(0.1);
              background-color:color-primary-fn(0.1);
            }
            .adm-checkbox.adm-checkbox-disabled .adm-checkbox-icon.adm-checkbox-icon{
              background-color:#D9D9D9;
              border-color:#D9D9D9;
              color:#ffffff;
            }
          }
      }
    }
    .productIntroduce{
      @include flex-vc;
      justify-content: space-between;
      .productIntroduceItem{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .productInfo{
          color: var(--color-primary);
          font-size: 12px;
          font-weight: 600;
        }
        .productName{
          color: #8C8C8C;
          font-size: 12px;
        }
      }
    }
  }
  .introduceBox{
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 16px;
    background-color: #fff;
    .introduceTitle{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      .line{
        width: 60px;
        height: 3px;
        background: linear-gradient(90deg, #FAF8FE 0%, var(--color-primary));
      }
      .introduceTitleText{
        font-size: 18px;
        color: #262626;
        font-weight: 600;
        margin: 0 16px;
      }
    }
    .introduceList{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .introduceItem{
        width: calc((100% - 12px) / 2);
        height: 62px;
        border-radius: 4px;
        background: linear-gradient(259.02deg, color-primary-fn(0.1), rgba(247, 242, 255, 0.4) 100%);;
        margin-bottom:12px;
        padding: 12px 16px;
        .introduceItemHeader{
          @include flex-vc;
          .introduceItemHeaderIcon{
            color: var(--color-primary);
            margin-right: 5px;
          }
          .introduceItemHeaderText{
            font-size: 14px;
            font-weight: 600;
            color: #262626;
          }
        }
        span {
          font-size: 10px;
          color:#8C8C8C ;
        }
      }
    }
    .specialReminderBox{
      margin-top: 12px;
      border-radius: 4px;
      padding: 10px;
      background-color: #F5F5F5;
      .title{
        font-size: 14px;
        font-weight: 600;
        color: #262626;
        margin-bottom: 10px;
        text-align: center;
      }
    }
  }
  .btnBox {
    .btn {
      border: none;
      padding: 0;
      height: 48px;
      width: 100%;
      background: $linear-gradient;
      line-height: 50px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
