@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.successPage {
  padding-top: 72px;
  height: 100%;
  text-align: center;
  background-color: $color-white;

  .successIcon {
    margin: 0 auto 10px;
    width: 120px;
    height: 96px;
    background-size: 100% 100%;
    background-image: url("~@imgs/information/success.png");
  }

  .title {
    margin-bottom: 24px;
    font-size: 36px;
    font-weight: bold;

    // color: #F0D6B1;
    color: transparent;
    background: linear-gradient(108.61deg, #0BC1FF 3.46%, #0E6BFF 97.1%);
    background-clip: text;
    line-height: 50px;
  }

  .text {
    font-size: 24px;
    color: #969CB6;
    line-height: 34px;
  }

  .textSecondary {
    margin-top: 10px;
    font-size: 18px;
    color: #969CB6;
    line-height: 25px;
  }

  .bold {
    padding: 0 2px;
    font-weight: bold;
    color: $color-primary;
  }

  .downloadBtn {
    z-index: 1;
    display: block;
    margin: 120px auto 16px;
    border: none;
    border-radius: 32.5px;
    padding: 0;
    width: 300px;
    height: 48px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: $color-white;
    background: $linear-gradient;
    line-height: 48px;
  }

  .textTip {
    font-size: 14px;
    line-height: 20px;
  }

  .tips {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    text-align: right;
    background: rgba($color: #000000, $alpha: 50%);

    .tipsTitle {
      position: absolute;
      right: 0;
      width: 58%;
      font-size: 16px;
      text-align: left;
      color: $color-white;
    }
  }
}
