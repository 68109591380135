@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.crmLogin {
  position: relative;
  padding: 20px;
  padding: 16px 14px;
  min-height: 100vh;
  background-color: $color-white;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(180.83deg, #FF4757 -.62%, rgb(255 154 154 / 0%) 71%);
    content: "";
  }
  
  .form {
    width: 100%;
    padding: 0 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }
  .phoneBox {
    position: relative;
    height: 50px;
    background: #f4f5f7;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin: 40px auto 0;
    .label {
      width: 170px;
      text-align: center;
      font-size: 16px;
    }
    :global {
      .adm-input-element {
        font-size: 16px;
        // font-weight: 600;
        line-height: 30px;
        &::placeholder {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }
  .inputBox {
    height: 48px;
    // border: 1px solid #e19459;
    margin: 15px auto;
    padding-left: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background: #f4f5f7;
    .code {
      width: 150px;
      text-align: center;
      color: #e19459;
    }
    :global {
      .adm-input-element {
        font-size: 15px;
        // font-weight: 600;
        line-height: 30px;
      }
    }
  }
  .weChatCode {
    font-size: 16px;
    margin-top: 50px;
    position: relative;
  }
  .downloadBtn {
    margin: 20px auto 0;
    border-radius: 80px;
    width: 200px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: $color-white;
    background: $linear-gradient;
    line-height: 48px;
    position: relative;

    &.disable {
      border: 1px solid $border-color;
      color: $placeholder-color;
      background: $background-color;
    }
  }
}
