@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.form {
  position: relative;
  .userInfoItem {
    padding: 0 20px;
    background-color: #fff;
    border-radius: 8px 8px 8px 8px;
    .flex {
      display: flex;
      align-items: center;
    }
    .assetInfomation{
      display: flex;
      justify-content: start;
      align-items: center;
      height: 60px;
      .walletIcon{
        height: 16px;
        width: 16px;
        padding-right: 8px;
      }
      .assetNum{
        font-size: 16px;
        line-height: 18px;
        color: #4E191E;
      }
    }
    .border {
      border-bottom: 1px solid #F2F2F2;
    }
    .formList{
      .formItem{
        .formItemTitle{
          height: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .leftItemBox{
            font-weight: 600;
            font-size: 14px;
            color: #333333;
            line-height: 18px;
          }
          .placeChoose{
            padding-right: 12px;
            font-size: 14px;
            color: #BFBFBF;
          }
          .chooseLabel {
            font-size: 16px;
            color: #666666;
            padding-right: 10px;
          }
        }
        .formItemContent{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          padding-bottom: 12px;
          .formItem{
            width: calc((100% - 16px) / 3);
            height: 28px;
            background: #F5F5F5;
            border-radius: 2px;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            color: #262626;
            box-sizing: border-box;
            margin-right: 8px;
            margin-bottom: 8px;
          }
          .formItem:nth-child(3n) {
            margin-right: 0;
          }
          .activeItem {
            background: color-primary-fn(0.1);
            color:var(--color-primary);
            font-weight: 600;
            border: 1px solid var(--color-primary);
          }
        }
      }
    }
    .icon {
      height: 14px;
      width: 14px;
      padding-right: 12px;
      padding-bottom: 8px;
    }
  }
  .userInfoItem {
     padding: 0 20px 0px;
     background-color: #fff;
     margin-bottom: 16px;
     border-radius: 8px;
     .userBaserInfo{
       padding-top: 16px;
       .userBaserInfoTitleIconBox {
         display: flex;
         align-items: center;
         span {
           font-size: 14px;
           font-weight: 600;
         }
         .ownNum{
          color: var(--color-primary);
         }
        .moneyIcon{
          height: 20px;
          margin-right: 8px;
        }
       }
       .warningText{
        margin-top: 4px;
        font-size: 12px;
        color: #8C8C8C;
        margin-bottom: 16px;
        }
       .formItem{
         height: 58px;
       }
     }
     .usercityInfo{
       .cityWarngingText{
        height: 58px;
        @include flex-vc;
        .warning {
          color: var(--color-primary);
        }
         span {
          font-size: 12px;
         }
       }
     }
     .userFormInfo {
       .assetInfomation{
         display: flex;
         justify-content: start;
         align-items: center;
         height: 60px;
         .userFormIcon{
           height: 20px;
           margin-right: 8px;
         }
         span {
          font-size: 14px;
         }
         .userFormText{
           margin-left: 5px;
           color: var(--color-primary);
           font-weight: 600;
         }
       }
       .arrow{
        height: 10px;
      }
    }
    }
  .btn {
    position: fixed;
    left: 16px;
    bottom: 32px;
    margin: 0 auto;
    border: none;
    padding: 0;
    width: 343px;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    background: $linear-gradient;
    line-height: 48px;
  }
  .lastBoxItem {
    margin-bottom: 8px;
    .formBox {
      margin-top: 8px;
      padding: 0 12px;
      background-color: #ffffff;
      border-radius: 8px;
    }
  }
  :global {
    .adm-input-element {
      font-size: 15px;
      line-height: 1;
      color: #262626;
      background-color: #ffffff;
    }
    
  
    .adm-list-body {
      background-color: transparent;
    }
  
    .adm-list-card {
      margin: 0;
    }
  
    .adm-list-item {
      padding: 0;
    }
  
    .adm-list-item-content {
      height: 52px;
      padding-right: 0;
      background-color: #ffffff;
      border: none;
    }
  
    .adm-form-item-label .adm-form-item-required-asterisk {
      display: none;
    }
  
    input:-webkit-autofill {
      box-shadow: 0 0 0 1000px #ffffff inset;
    }
  
    .adm-form-item-label {
      font-size: 15px;
      width: 90px;
      line-height: 2;
      color: #333333;
      background-color: transparent;
    }
    .adm-list-item-content-prefix {
      @include flex-vc;
    }
  
    .adm-list-item-content-arrow {
      // transform: rotate(90deg);
      font-size: 14px;
      padding-bottom: 8px;
    }
  
    .adm-form-item-feedback-error {
      text-align: left;
    }
    .adm-list-default .adm-list-body {
      border: none;
    }
    .adm-divider-horizontal{
      margin: 0;
    }
    .adm-input-element {
      text-align: right;
    }
    .adm-form-item-feedback-error{
      display: none;
    }
  }
}
.btnList{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0 20px;
}
.noticle{
  font-size: 12px;
  background: #FFF7F5;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0 16px;
  color: #FC5555;
}

