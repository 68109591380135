@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.fullPage {
  min-height: 100vh;
  background-color: #fff;
  padding-top: 50px;
  .empty {
    width: 158px;
    height: 90px;
    display: block;
    margin: 57px auto 7px;
  }
  .authResultText {
    font-size: 16px;
    color: #262626;
    text-align: center;
  }
  .authResultTips {
    font-size: 13px;
    color: #8C8C8C;
    text-align: center;
    margin-top: 15px;
  }
  .footerBtn {
    width: 206px;
    margin: 52px auto 0;
    border-radius: 24px;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: $color-white;
    line-height: 48px;
    background: $linear-gradient;
  }
}
