@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.inlinePage {
  padding-top: 48px;
  height: 100%;
  overflow: hidden;
  .iframe {
    border: none;
    width: 100%;
    height: calc(100vh - 48px);
  }
}
