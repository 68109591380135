@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.cancelAccount {
  height: 100%;
  padding: 0 30px;
  background-color: #fff;
  .pageTop {
    padding-top: 80px;
    text-align: center;
    .pageTopIcon {
      width: 56px;
      height: 56px;
    }
    .pageTopText {
      margin-top: 20px;
      font-size: 16px;
      color:#262626;
    }
  }
  .splitLine {
    width: 100%;
    height: 1px;
    background-color: #D9D9D9;
    margin: 20px 0;
  }
  .pageBottom {
    .cancelAccountTips {
      .tipsItem {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        .tipsItemCircle {
          width: 6px;
          height: 6px;
          background-color: #F5222D;
          margin-right: 6px;
          border-radius: 50%;
        }
        .tipsItemText {
          font-size: 12px;
          color: #8C8C8C;
        }
      }
    }
  }
  .btn {
    margin-top: 32px;
    border: none;
    padding: 0;
    height: 48px;
    background: $linear-gradient;
    line-height: 50px;
  }
}


.bodyClassName {
  border-radius: 10px;
}

.modalContent {
  .title {
    margin-bottom: 16px;
    font-size: 18px;
    text-align: center;
  }

  .btnBox {
    display: flex;

    .btn {
      @include flex-cc;

      border-radius: 24px;
      height: 48px;
      font-weight: bold;
      color: $color-white;
      flex: 1;

      & + .btn {
        margin-left: 8px;
      }

      &::before {
        border-radius: 24px;
      }

      &:first-child {
        border: 1px solid $color-primary;
        color: $color-primary;
      }

      &:last-child {
        background: $linear-gradient;
      }
    }
  }
}