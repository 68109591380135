@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.productList {
  .empty {
    margin-top: 10vh;
  }

  :global {
    .adm-infinite-scroll {
      margin-top: -16px;
    }
  }
}
