@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.successPage {
  position: relative;
  padding-bottom: 100px;
  .backIcon {
    position: absolute;
    font-size: 16px;
    left: 16px;
    top: 20px;
    color: #ffffff;
  }
  .header {
    padding: 20px 16px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 200px;
    background-image: url("~@imgs/register/new-theme/head-bg.png");

    .logoBox {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      @include flex-cc;
      .logo {
        margin-right: 10px;
        width: 42px;
        height: 42px;
        background-size: 100%;
        background-image: url("~@imgs/register/red-theme/logo-big.png");
      }

      .logoBoxRight {
        display: flex;
        flex-direction: column;
        .logoText {
          width: 80px;
          height: 21px
        }
        .youqianLogoText {
          width: 54px;
          height: 21px
        }
        .logoText2 {
          margin-top: 4px;
          width: 79px;
          height: 11px
        }
      }
    }

    .resultBox {
      margin-top: 18px;
      width: 343px;
      background-color: #fff;
      border-radius: 8px;
      padding: 24px 0;
      .resultSuccessIcon {
        padding: 0 0 20px;
        .successIcon {
          display: block;
          width: 64px;
          height: 64px;
          margin: 0 auto;
        }
      }
      .noResult {
        text-align: center;
        .noResultTitle {
          font-size: 24px;
          font-weight: 600;
          color: #FF4D4F;
        }
        .noResultSubTitle {
          margin-top: 14px;
          font-size: 16px;
          color: #50515C;
        }
      }
      .copywritingCon {
        padding: 0 20px;
  
        .row {
          position: relative;
          padding-left: 20px;
  
          .left {
            position: absolute;
            left: 5px;
            padding: 10px 0;
            width: 30px;
            text-align: center;
            background-color: $color-white;
  
            .throughIcon {
              width: 22px;
              height: 22px;
            }
  
            .phoneIcon {
              width: 30px;
              height: 30px;
            }
          }
  
          .right {
            border-left: 1px solid #D4E8FD;
            padding-top: 10px;
            padding-bottom: 30px;
            padding-left: 20px;
  
            &.borderNone {
              border-left: 1px solid transparent;
              padding-bottom: 0;
            }
  
            .title {
              font-size: 18px;
              font-weight: 600;
              line-height: 26px;
              color: $text-gray-color;
  
              &.blue {
                color: $color-primary;
              }
            }
  
            .text {
              font-size: 16px;
              line-height: 24px;
              color: $text-gray-color;
  
              .origan {
                font-weight: bold;
                color: #FFA630;
              }
            }
          }
        }
      }
      .downloadBtn {
        margin: 22px 16px 0;
        border-radius: 80px;
        height: 48px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: $color-white;
        background: $linear-gradient;
        // line-height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
  
        &.disable {
          border: 1px solid $border-color;
          color: $placeholder-color;
          background: $background-color;
        }
      }
    }
  }
  .footer {
    .footerTitleBox {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      .rectangle {
        width: 12px;
        height: 12px;
      }
      .footerTitle {
        font-size: 20px;
        color: #595959;
        margin: 0 10px;
        font-weight: bold;
      }
    }
    .footerDatas {
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      .footerDataItem {
        .footerDataItemIcon {
          width: 80px;
          height: 80px;
          margin-left: 3px;
        }
        .footerDataItemText1 {
          font-size: 16px;
          color: #50515C;
          font-weight: bold;
          text-align: center;
        }
        .footerDataItemText2 {
          color: #8C8C8C;
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }

  // 关注公众号
  .followOfficialAccountBox {
    margin: 4px 16px;
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    .followOfficialAccountBoxTitle {
      display: block;
      width: 264px;
      height: 44px;
      margin: 0 auto 45px;
    }
    .followOfficialModule {
      margin-bottom: 34px;
      .followOfficialModuleTitle {
        display: flex;
        align-items: center;
        .followOfficialModuleTitleLine {
          width: 4px;
          height: 14px;
          background: #FF4D4F;
          margin-right: 8px;
        }
        .followOfficialModuleTitleText {
          font-size: 16px;
          font-weight: bold;
        }
      }
      .followOfficialModuleContent {
        margin-top: 11px;
        display: flex;
        justify-content: space-between;
        .officialAccountSearch {
          width: 140px;
          height: 120px;
        }
        .officialAccountCode {
          width: 120px;
          height: 120px;
        }
        .followOfficialModuleContentRight {
          width: 140px;
          font-size: 14px;
          color: #262626;
          &.center {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .officialAccountBtn {
            margin-top: 20px;
            width: 144px;
            height: 40px;
          }
        }
      }
    }
  }

  .productBox {
    padding: 14px 16px;
    // background-color: $color-white;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    // :global {
    //   .productCard {
    //     margin-bottom: 0;
    //     border-bottom: 1px solid $border-color;
    //     border-radius: 0;
    //     padding-right: 0;
    //     padding-left: 0;
    //   }

    //   .adm-infinite-scroll {
    //     margin-top: 0;
    //   }
    // }
  }

  .card {
    margin-top: 10px;
    margin-bottom: 12px;
    border-radius: 8px;
    padding: 16px;
    background-color: $color-white;
  
    &:last-child {
      margin-bottom: 0;
    }
  
    .top {
      @include flex-sbc;
  
      .nameBox {
        @include flex-vc;
  
        .icon {
          overflow: hidden;
          border-radius: 2px;
          width: 20px;
          height: 20px;
        }
  
        .name {
          width: 80px;
          font-size: 14px;
          font-weight: bold;
  
          @include ellipsis;
        }
  
        .tag {
          margin-left: 6px;
          border-radius: 2px;
          padding: 2px 6px;
          font-size: 12px;
          color: $color-primary;
          background: $light-red-bg;
        }
      }
  
      .numberOfApplicants {
        position: relative;
        top: 10px;
        font-size: 12px;
        color: $text-gray-color;
      }
    }
  
    .bottom {
      @include flex-sbc;
  
      margin-top: 4px;
  
      .left {
        display: flex;
  
        .limitBox {
          width: 116px;
        }
  
        .rateBox {
          width: 66px;
          text-align: right;
        }
  
        .limit,
        .interestRate {
          font-size: 24px;
          font-weight: bold;
          line-height: 36px;
          color: $color-primary;
        }
  
        .limitText {
          font-size: 12px;
          color: $text-gray-color;
        }
  
        .interestRate {
          // color: $color-primary;
  
          .unit {
            font-size: 16px;
            line-height: 30px;
          }
        }
  
        .interestRateText {
          padding-right: 15px;
          color: $text-gray-color;
        }
      }
  
      .apply {
        @include flex-cc;
  
        border-radius: 40px;
        width: 88px;
        height: 36px;
        font-size: 14px;
  
        // font-weight: 600;
        color: $color-white;
        background: $linear-gradient;
        mix-blend-mode: normal;
        line-height: 20px;
  
        &.disable {
          background: $border-color;
        }
      }
    }
  }
}
