@import "@/styles/var.scss";
@import "@/styles/mixin.scss";


.title {
  font-size: 24px;
  color: #262626;
  text-align: center;
  padding: 48px 0 80px;
}

.cameraWrapper {
  position: relative;
  width: 246px;
  height: 246px;
  margin: auto;
  border: 6px solid #F0F0F0;
  border-radius: 50%;
}

.videoElement {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  clip-path: circle(50% at center);
}

.loadingCircle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 6px solid transparent;
  border-top: 6px solid #FF2437;
  border-radius: 50%;
  animation: spin 5s linear;
}

@keyframes spin {
  100% {
      transform: rotate(360deg);
  }
}

.authenticationMessage {
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  color: green;
}

