@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.authorizePage {
  padding: 48px 20px;
  line-height: normal;
  background-color: #fbfafd;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 432px;
  background-image: url('~@imgs/ios-skin/head-bg.png');
  .authorizeMainBox{
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .creditLimit{
      .creditLimitTitle{
        @include flex-vc;
        color: #ffffff;
        font-size: 16px;
      }
      .creditNum{
        margin-top: 8px;
        font-size: 40px;
        font-weight: 700;
        color: #ffffff;
        text-align: center;
      }
      .buttonText{
        font-size: 12px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: center;
        color: #ffffff;
      }
      .actualWarningText{
        text-align: center;
        margin-top: 8px;
        color: #FFFFFF;
        font-size: 12px;
      }
    }
    .productCard{
      position: relative;
      z-index: 1;
      margin-top: 16px;
      padding: 16px;
      width: 100%;
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
      .productCardTitleBox{
        position: relative;
        z-index: 1;
        @include flex-vc;
        margin-bottom: 16px;
        justify-content: center;
        .line1{
          width: 48px;
          height: 1px;
          background: linear-gradient(90deg, #7D5BDB 0%, rgba(125, 91, 219, 0) 100%);
          transform: rotate(-180deg);
        }
        .line2{
          width: 48px;
          height: 1px;
          background: linear-gradient(90deg, #7D5BDB 0%, rgba(125, 91, 219, 0) 100%);
        }

        .introduceTitle {
          margin: 0 12px;
          font-size: 16px;
          color: #262626;
        }
      }
      .maskBox{
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        width: 343px;
        height: 294px;
        background: linear-gradient(180deg, #FFE8E8 0%, #FFFFFF 30.18%);
        img {
        height: 105px;
          position: absolute;
          right: 10px;
          top: 0;
        }
      }
      .jufuMaskBox {
        background: linear-gradient(180deg, #F5E8FF 0%, #FFFFFF 30.18%);
      }
      .multipleProcessContentItem{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        z-index: 1;
        .multipleProcessContentItemLeft{
          width: 90%;
          display: flex;
          justify-content: start;
          align-items: center;
          .contentItemIcon {
            width: 36px;
            height: 36px;
            margin-right: 10px;
            border-radius: 6px;
          }
          .multipleProcessContentItemInfo{
            span{
              font-size: 16px;
              color: #262626;
            }
            div {
              color: #8E8E8E;
              font-size: 12px;
            }
          }
          .multipleProductInfo{
            display: flex;
            flex-direction: column;
            justify-content: start;
            // align-items: center;
            .partnerCompanyName{
              color: #8E8E8E;
              font-size: 12px;
            }
            .multipleProductInfoTop{
              display: flex;
              justify-content: start;
              align-items: center;
              margin-bottom: 2px;
              .productName{
                font-weight: 700;
                font-size: 14px;
                color: #262626;
              }
              .platformName{
                text-align: center;
                margin-left: 10px;
                padding: 2px 4px;
                font-size: 12px;
                line-height: 1.3;
                color: #E4A26A;
                border: 1px solid #E4A26A;
                border-radius: 2px;
              }
            }
          }
        }
        .multipleProcessContentItemRight{
            :global {
              .adm-checkbox{
                --icon-size:14px
              }
              .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
                border-color:color-primary-fn(0.1);
                background-color:color-primary-fn(0.1);
              }
              .adm-checkbox.adm-checkbox-disabled .adm-checkbox-icon.adm-checkbox-icon{
                background-color:#D9D9D9;
                border-color:#D9D9D9;
                color:#ffffff;
              }
            }
        }
      }
      .productIntroduce{
        @include flex-vc;
        justify-content: space-between;
        margin-bottom: 16px;
        .productIntroduceItem{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .productInfo{
            color: var(--color-primary);
            font-size: 12px;
            font-weight: 600;
          }
          .productName{
            color: #8C8C8C;
            font-size: 12px;
          }
        }
      }
      .protocolIntroduce{
        .protocolIntroduceTips{
          color: var(--color-primary);
        }
        .footerTextRed {
          // font-size: 12px;
          color: var(--color-primary);
        }
      }
    }
    .protocolListBox{
      padding: 16px;
      width: 100%;
      height: 228px;
      background-color: #fff;
      border-radius: 8px;
      overflow: auto;
      margin-top: 16px;
      .agreementContent{
        background-color: #F5F5F5;
        border-radius: 4px;
        padding: 10px;
      }
    }
    .footerButtonBox{
      margin-top: 16px;
      width: 100%;
      .btnBox {
        // margin-top: 16px;
        .btn {
          border: none;
          padding: 0;
          height: 48px;
          background: $linear-gradient;
          line-height: 50px;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .cancleBtn{
        font-size: 14px;
        line-height: 20px;
        margin-top: 8px;
        color: #8C8C8C;
        text-align: center;
      }
      .checkedActionBox{
        margin-top: 8px;
        display: flex;
        font-size: 10px;
        .checkedIcon {
          flex-shrink: 0;
          width: 14px;
          height: 14px;
          margin-right: 8px;
          margin-bottom: .5px;
        }
        .checked {
          flex-shrink: 0;
          // display: inline-block;
          vertical-align: text-bottom;
          margin-right: 8px;
          margin-bottom: .5px;
          border: 1px solid #D9D9D9;
          border-radius: 100%;
          width: 14px;
          height: 14px;
        }
        .footerTextRed {
          // font-size: 12px;
          color: var(--color-primary);
        }
        .checkFont{
          color: #979797;
        }
      }
    }
  }

  .retainModal{
    width: 300px;
    .retainBox{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .warningText{
        font-size: 18px;
        font-weight: 600;
        color: #262626;
        margin: 24px 0;
      }
      .continueBtn{
        border: none;
        padding: 0;
        width: calc(275px - 32px);
        height: 48px;
        font-size: 16px;
        font-weight: bold;
        background: $linear-gradient;
        line-height: 48px;
      }
      .giveUpBtn{
        margin-top: 16px;
       text-align: center;
       color: #8C8C8C; 
       font-size: 14px;
      }
      .cancleBtn {
        position: absolute;
        bottom: 0;
      }
    }
  }
  :global {
    .adm-center-popup-wrap {
      max-width: none;
    }
  }
}
.jufuAuthorizePage{
  background-image: url('~@imgs/jufu/result-backgroud.png') !important;
}

.protcolPopup {
  .popupTitle {
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
  }
  .protocolIdsBox {
    padding: 0 15px;
    .protocolIdItem {
      padding: 15px 0;
      border-bottom: 1px solid #DDD;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      .arrowBlack {
        width: 10px;
      }
      .chooseArrow {
        transform: rotate(90deg);
      }
    }
    .protocolChild{
      padding-left: 20px;
      height: 40px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
