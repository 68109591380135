@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.detainmentPopup {

}

.resetModalContent {
  border-radius: 10px;
  text-align: center;
  background: $color-white;
  box-shadow: 0 9px 28px 8px rgb(0 0 0 / 5%), 0 6px 16px rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%);
  background-image: url("~@imgs/yqzs/modal-bg.png");
  background-size: 100% 100%;
  width: 302px;
  margin: 0 auto;
  .productBox {
    padding: 35px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .productLogo {
      width: 60px;
      height: 60px;
      background-color: #fff;
      border-radius: 8px;
      object-fit: cover;
    }
    .productName {
      margin-top: 10px;
      font-size: 17px;
      font-weight: bold;
      color: #000;
    }
    .productLimit {
      margin-top: 5px;
      font-size: 40px;
      font-weight: bold;
      color: #F50F23;
    }
  }
  .footerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    .applyTips {
      color: #8C8C8C;
      font-size: 14px;
    }
    .btn {
      margin-top: 14px;
      width: 218px;
      border: none;
      padding: 0;
      height: 48px;
      background: $linear-gradient;
      line-height: 50px;
    }
    .otherBtn {
      padding: 12px 0;
      color: #F50F23;
      font-size: 13px;
    }
  }
}
