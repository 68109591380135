@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.applicationRecord {
  padding: 66px 16px 16px;

  .itemTitle {
    font-size: 14px;
    color: #262626;
  }
  .itemText {
    font-size: 12px;
    color: #8C8C8C;
  }
}
