@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.advertising {
  min-height: 100vh;
  line-height: normal;
  background-color: #F3F5F7;
  position: relative;
  background: url("~@imgs/yqzs/page-bg.png") no-repeat;
  background-size: 100% 280px;
  .pageTitle {
    padding: 20px 0 10px;
    font-size: 17px;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }
  .noticeBar {
    background-color: #FFE2D6;
    border: none;
    color: #8C5550;
    font-size: 12px;
    height: 25px;
  }
  .swiperBox {
    margin-top: 16px;
    // width: 323px;
    // margin: 16px auto 0;
    // :global {
    //   .adm-swiper-slide {
    //     // width: 323px;
    //     box-sizing: border-box;
    //     // margin: 0 15px;
    //   }
    // }
    .swiperBoxItem {
      padding: 18px;
      border-radius: 6px;
      background: linear-gradient(180deg, #FFC6C7 0%, #FFFFFF 100%);
      margin: 0 auto;
      width: 323px;
      position: relative;
      .jingxuan {
        width: 52px;
        height: 25px;
        position: absolute;
        right: 0;
        top: 0;
      }
      .productHeader {
        display: flex;
        align-items: center;
        .productLogo {
          width: 19px;
          height: 19px;
          margin-right: 10px;
          border-radius: 2px;
        }
        .productName {
          color: #B00503;
          font-weight: bold;
          font-size: 18px;
        }
      }
      .limitBox {
        margin-top: 12px;
        display: flex;
        align-items: center;
        .limitBoxLeft {
          flex: 1;
          .limitLabel {
            font-size: 13px;
          }
          .limitValue {
            font-size: 36px;
            color: #F50F23;
            font-weight: bold;
          }
        }
        .limitBtn {
          width: 98px;
          padding: 7px 0;
          border-radius: 45px;
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          text-align: center;
          background: linear-gradient(137deg, #FF773D 0%, #FF0C0C 100%);
        }
      }
      .productOtherInfo {
        margin-top: 10px;
        display: flex;
        .productOtherInfoItem {
          padding: 3px 6px;
          background-color: #FFEEEE;
          font-size: 12px;
          color:#E03E2F;
          border-radius: 2px;
          margin-right: 3px;
        }
      }
    }
  }
  .advantage {
    padding: 0 34px;
    margin: 18px 0 0;
    display: flex;
    justify-content: space-between;
    .advantageItem {
      .advantageItemIcon {
        width: 53px;
        height: 53px;
        object-fit: cover;
      }
      .advantageItemText {
        margin-top: 8px;
        font-size: 12px;
        color: #262626;
        text-align: center;
      }
    }
  }
  .homeCarouselBannerBox {
    margin-top: 22px;

    .homeCarouselBannerItem {
      margin: 0 auto;
      width: 341px;
      height: 79px;
      border-radius: 6px;

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .productModule {
    margin-top: 24px;
    .productModuleItem {
      margin-top: 21px;
      .productModuleTitleBox {
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        padding: 0 21px;
        .productModuleTitleBefore {
          width: 4px;
          height: 16px;
          background-color: #FF0016;
          margin-right: 8px;
          border-radius: 6px;
        }
        .productModuleTitle {
          font-size: 17px;
          font-weight: bold;
        }
      }
      .productList {
        padding: 0 14px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &::after {
          content: '';
          width: 30%;
        }
      }
    }
  }
  .logoText {
    display: block;
    width: 70px;
    height: 17px;
    margin: 30px auto 24px;
  }
  .stepBox {
    padding: 0 36px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .dashedline1 { 
      width: 53px;
      height: 8px;
      position: absolute;
      left: 100px;
      top: 10px;
    }
    .dashedline2 { 
      width: 53px;
      height: 8px;
      position: absolute;
      right: 100px;
      top: 10px;
    }
    .stepItem {
      text-align: center;
      .stepItemIcon {
        width: 28px;
        height: 28px;
      }
      .stepItemName {
        margin-top: 10px;
        font-size: 13px;
        color: #888A8B;
      }
    }
  }
  .footer {
    padding: 12px 22px 80px;
    background-color: #EFF0F1;
    .footerText1 {
      font-size: 12px;
      font-weight: 300;
      color: #A4A4A4;
    }
    .footerText2 {
      margin-top: 10px;
      font-size: 12px;
      color: #B9B9B9;
      text-align: center;
    }
  }

  

#loading-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

@-webkit-keyframes loadingRotate {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(0.6);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes loadingRotate {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(0.6);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}
  .root{
    width: 100%;
    height: 100vh;
    background-color: #EFF0F1;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
  }
  .ballClipRotateMultiple {
    position: relative;
    width: 50px;
    height: 50px;
  }

  .ballClipRotateMultiple>div {
    position: absolute;
    left: 0px;
    top: 0px;
    border: 2.5px solid #007AEA;
    border-bottom-color: #0000;
    border-top-color: #0000;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    -webkit-animation: loadingRotate 1s 0s ease-in-out infinite;
    animation: loadingRotate 1s 0s ease-in-out infinite;
  }

  .ballClipRotateMultiple>div:last-child {
    display: inline-block;
    top: 10px;
    left: 10px;
    width: 15px;
    height: 15px;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    border-color: #007AEA #0000;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
  }

  #loading-box .dot {
    display: inline-block;
    border-radius: 50%;
    width: 2px;
    height: 2px;
    animation: dotting .8s infinite step-start;
  }

  @keyframes dotting {
    25% {
      box-shadow: 2px 0 0 #007AEA;
    }

    50% {
      box-shadow: 2px 0 0 #007AEA, 6px 0 0 #007AEA;
    }

    75% {
      box-shadow: 2px 0 0 #007AEA, 6px 0 #007AEA, 10px 0 0 #007AEA;
    }
  }

  @-webkit-keyframes dotting {
    25% {
      box-shadow: 2px 0 0 #007AEA;
    }

    50% {
      box-shadow: 2px 0 0 #007AEA, 6px 0 0 #007AEA;
    }

    75% {
      box-shadow: 2px 0 0 #007AEA, 6px 0 #007AEA, 10px 0 0 #007AEA;
    }
  }
}
