@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.ModalBodyClassName {
  padding: 0 !important;
  background-color: transparent;

  :global {
    .adm-modal-content {
      overflow: hidden;
      border-radius: 0;
      padding: 0;
    }
  }
}

:global {
  .adm-center-popup {
    --border-radius: 0;
  }

  .adm-center-popup-wrap {
    max-width: 320px;
  }
}

.modalBox {
  display: flex;
  max-height: 65vh;
  flex-flow: column;

  .content {
    flex: 1;
    overflow: auto;
    width: 320px;
  }

  .closeBox {
    margin-top: 18px;
    text-align: center;
  }

  .close {
    display: inline-block;
    font-size: 32px;
    color: $color-white;
    flex: none;
  }
}
