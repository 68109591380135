@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.protocolList {
  .title {
    margin-bottom: 12px;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: #262626;
  }

  .subTitle {
    margin: 8px 0;
    font-size: 14px;
  }

  p {
    text-align: justify;
    text-indent: 2em;
    line-height: 20px;
  }

  .bold {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
  }

  .emptyLine {
    height: 20px;
  }

  .agreementContent{
    background-color: #F5F5F5;
    border-radius: 4px;
    padding: 10px;
  }
}
