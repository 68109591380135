@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.empty {
  text-align: center;

  .img {
    width: 154px;
    height: 128px;
  }

  .text {
    margin-top: 12px;
    font-size: 16px;
    color: $text-gray-color;
  }
}
