@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.loanApplication {
  .top {
    position: relative;
    height: 200px;
    background-size: 100% 100%;
    background-image: url("~@imgs/index/detail-top-bg-red.png");
  }

  .header {
    border-bottom: none;
    color: $color-white;
    background-color: transparent;
  }

  .block {
    margin: 16px 16px 0;
    border-radius: 8px;
    padding: 20px 16px;
    background-color: $color-white;
  }

  .marginTop0 {
    margin-top: 0 !important;
  }

  .borrowBox {
    position: absolute;
    bottom: -30px;
    left: 0;
    margin: 0 16px;
    border-radius: 8px;
    padding: 20px 16px;
    width: calc(100% - 32px);
    background-color: $color-white;
  }

  .describe {
    font-size: 14px;
  }

  .inputBox {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $line-color;
    padding-bottom: 8px;
    font-size: 36px;
    font-weight: bold;
    line-height: 50px;

    :global {
      .adm-input-element {
        font-size: 36px;
        font-weight: bold;
      }
    }
  }

  .selectMonthBox {
    margin-top: 16px;

    @include flex-sbc;

    .select {
      position: relative;
      padding-right: 20px;
      color: $placeholder-color;

      &.currentMonth {
        color: $color-black;
      }

      /* 右箭头 */
      &::after {
        position: absolute;
        top: 34%;
        right: 3px;
        border: 1px solid $placeholder-color;
        border-color: $placeholder-color $placeholder-color transparent transparent;
        width: 8px;
        height: 8px;
        content: "";
        transform: translateY(-50%) rotate(135deg);
      }
    }
  }

  .calculateBox {
    display: flex;
    margin-top: 46px;

    >div {
      flex: 1;
      position: relative;
      padding-left: 20px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        &::after {
          width: 0;
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 24px;
        transform: translateY(-50%);
        content: "";
        background-color: $line-color;
      }

      .label {
        margin-bottom: 2;
        font-size: 12px;
        color: $text-gray-color;
      }

      .value {
        font-size: 16px;
        font-weight: 600;
      }

      .grayColor {
        color: $text-gray-color;
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 8px 16px;
    width: 100%;
    background-color: $color-white;

    .btn {
      @include flex-cc;

      border-radius: 80px;
      width: 100%;
      height: 48px;
      font-size: 16px;
      font-weight: bold;
      color: $color-white;
      background: $linear-gradient;

      &::before {
        border-radius: 24px;
      }
    }
  }

  .title {
    margin-top: 20px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
  }

  .content {
    font-size: 12px;
    color: #595959;
    line-height: 17px;

    & + .content {
      margin-top: 4px;
    }
  }

  .askCondition {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -8px;

    >div {
      margin-bottom: 8px;
      width: 33.33%;

      .checkCircle {
        margin-right: 4px;
        font-size: 14px;
        color: #FF4D4F;
      }
    }
  }
}

.monthBox {
  background-color: $background-color;

  .row {
    padding: 16px;
    font-size: 16px;
    text-align: center;

    &.active {
      font-weight: bold;
      color: $color-primary;
      background-color: $color-white;
    }
  }
}

.resetModalContent {
  border-radius: 10px;
  padding: 30px 16px 20px;
  text-align: center;
  background: $color-white;
  box-shadow: 0 9px 28px 8px rgb(0 0 0 / 5%), 0 6px 16px rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%);

  .img {
    width: 107px;
    height: 87px;
  }

  .title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
  }

  .productCon {
    margin-bottom: 16px;
    border-radius: 4px;
    padding: 12px;
    background: $light-red;
  }

  .headline {
    font-weight: bold;
    color: #F19993;

    @include flex-cc;

    .moneyIcon {
      position: relative;
      display: inline-block;
      margin: 0 12px;
      border-radius: 100%;
      width: 16px;
      height: 16px;
      background: linear-gradient(132.88deg, #F2CAC7 4.51%, #FFE7E6 89%);

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5px;
        height: 5px;
        background-color: $light-red;
        content: "";
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
      }
    }
  }

  .productInfo {
    margin-top: 12px;
    border-radius: 4px;
    padding: 16px 12px;
    text-align: left;
    background-color: $color-white;

    .nameBox {
      @include flex-sbc;

      .left {
        @include flex-vc;
      }

      .icon {
        margin-right: 8px;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        background: linear-gradient(180deg, #F2F4F6 0%, #E0E2E4 100%);
      }

      .name {
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        color: #40404E;
      }

      .amount {
        font-size: 22px;
        font-weight: bold;
        color: #FF5A0F;

        .unit {
          margin-left: 4px;
          font-size: 12px;
        }
      }
    }

    .rateDeadline {
      margin-top: 10px;
      font-size: 12px;
      color: $text-gray-color;

      @include flex-vc;

      .line {
        display: inline-block;
        margin: 0 8px;
        width: 1px;
        height: 16px;
        background-color: $placeholder-color;
      }
    }
  }

  .btn {
    border: none;
    padding: 0;
    height: 48px;
    background: $linear-gradient;
    line-height: 50px;
  }
}
