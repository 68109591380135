@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.successPage {
  padding: 64px 20px;
  background-color: #fbfafd;
  min-height: 100vh;
  .productLogo{
    height: 44px;
    width: 44px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
  }
  .monetizationBox{
    .monetizationItem1{
      background: linear-gradient(180deg, #FFF3F3 0%, #FFFFFF 25.5%);
      padding: 16px 12px;
      border-radius: 8px;
      .monetizationItem1Header{
        display: flex;
        align-items: center;
        .productIntroduceTitle{
          width: calc(100% - 58px);
          .title{
            font-size: 12px;
            span {
              color: var(--color-primary);
            }
          }
          .estimateNumBox {
            width: 100%;
            height: 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .estimateNum{
              display: flex;
              align-items: center;
              .loansLimitMax{
                height: 25px;
                font-size: 18px;
                font-weight: 600;
                color: #262626;
              }
              span {
                 font-size: 10px;
              }
            }
            .warningText{
              font-size: 10px;
              line-height: 12px;
              color: #8C8C8C;
            }
          }
        }
      }
      .tagList{
        margin-top: 16px;
        height: 30px;
        background-color: #F8F8F8;
        border-radius: 2px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 8px 24px;
        .tagItem{
          .icon{
            color: #8C8C8C;
            margin-right: 2px;
          }
        }
      }
      .btn {
        margin-top: 16px;
        border: none;
        padding: 0;
        height: 48px;
        background: $linear-gradient;
        line-height: 50px;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
      }
      .checkedBox{
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        .primary{
          color: var(--color-primary)

        }
        :global {
          .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
            background-color:var(--color-primary);
            border-color:var(--color-primary);
          }
        }
      }
    }
    .jufumonetizationItem1{
      background: linear-gradient(180deg, #FAF3FF 0%, #FFFFFF 25.5%) !important;
    }
    .monetizationItem2{
      background: #fff ;
      padding: 16px 12px;
      border-radius: 8px;
      width: 100%;
      margin-top: 16px;
      .monetizetionItem2Header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .introduceBox{
          display: flex;
          align-items: center;
          justify-content: start;
          .limitPrice{
            font-size: 16px;
            font-weight: 600;
            color: #262626;
          }
          .productName{
            font-size: 10px;
            color: #8C8C8C;
          }
        }
        .BtnBox{
          display: flex;
          flex-direction: column;
          align-items: center;
          .btnItem2{
            height: 29px;
            width: 96px;
            background: $linear-gradient;
            border: none;
          }
          .peopleNum{
            margin-top: 2px;
            font-size: 10px;
            color: #8C8C8C;
          }
        }
      }
      .checkedBox{
        margin-top: 16px;
        display: flex;
        justify-content: start;
        align-items: center;
        font-size: 10px;
        .primary{
          color: var(--color-primary);
        }
        :global {
          .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
          }
        }
      }
    }
    .moreMonetization{
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .moreMonetizationItem{
        padding: 12px;
        width:calc((100% - 16px) / 2);
        height: 64px;
        background-color: #fff;
        display: flex;
        border-radius: 4px;
        .productIntroduce{
          font-size: 12px;
          width: calc(100% - 44px - 22px);
          .moreMonetizationItemTitle{
            font-weight: 600;
            color: #262626;
            white-space: nowrap;
            overflow: hidden; /* 如果文本超出容器，此属性将隐藏超出的部分 */
            text-overflow: ellipsis; 
          }
        }
      }
    }
    .noMoreProductBox{
      margin-top: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      .line1{
        width: 48px;
        height: 1px;
        transform: rotate(-180deg);
        background:linear-gradient(90deg, #BFBFBF 0%, rgba(191, 191, 191, 0) 100%);
      }
      .line2 {
        width: 48px;
        height: 1px;
        background:linear-gradient(90deg, #BFBFBF 0%, rgba(191, 191, 191, 0) 100%);
      }
      .noMoreText {
        font-size: 14px;
        color: #BFBFBF;
        margin: 0 12px;
      }
    }
  }

  .defalutPageBox {
    line-height: normal;
    background-image: url('~@imgs/ios-skin/head-bg.png');
    background-size: 100% 432px;
    background-repeat: no-repeat;
    .main {
      padding: 20px 16px 10px;
      background-repeat: no-repeat;
      background-size: 100% 432px;
  
      .logoBox {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
  
        @include flex-cc;
  
        .logo {
          margin-right: 10px;
          width: 49px;
          height: 49px;
          background-size: 100%;
          background-image: url("~@imgs/register/red-theme/logo-big.png");
        }
  
        .logoBoxRight {
          display: flex;
          flex-direction: column;
          .logoText {
            width: 80px;
            height: 21px
          }
          .youqianLogoText {
            width: 80px;
            height: 18px
          }
          .youqianTextLogoSvg{
            width: 80px;
            height: 20px;
            color: #ffffff;
          }
          .logoText2 {
            margin-top: 4px;
            width: 79px;
            height: 11px
          }
          .logoText3 {
            margin-top: 1px;
            font-size: 12px;
            color: #fff;
            font-weight: 600;
            transform: scale(.86) translateX(-10px);
          }
        }
      }
  
      .applyResultBox {
        margin-top: 15px;
        background: linear-gradient(314deg, #FFFAF9 0%, #FFEEE6 100%);
        border-radius: 7px;
        border: 2px solid var(--color-primary);
        display: flex;
        align-items: center;
        padding: 9px;
        .applyResultBoxIcon {
          width: 60px;
          height: 60px;
          flex-shrink: 0;
          margin-right: 8px;
        }
        .applyResultBoxRight {
          .applyResultBoxText1 {
            font-size: 16px;
            font-weight: bold;
            color: #000;
            .applyPrductionName {
              color: var(--color-primary);
            }
          }
          .applyResultBoxText2 {
            margin-top: 4px;
            font-size: 14px;
            color: #967569;
            .highlight {
              color: var(--color-primary);
            }
          }
        }
      }
  
      .containers {
        margin-top: 13px;
        border-radius: 14px;
        background-color: #fff;
        padding: 36px;
        &.passBox {
          height: 460px;
        }
        &.failBox {
          height: 550px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .noPass {
            width: 72px;
            height: 72px;
          }
          .failBoxText1 {
            margin-top: 16px;
            font-size: 21px;
            font-weight: bold;
            color: var(--color-primary);
          }
          .failBoxText2 {
            margin-top: 25px;
            font-size: 16px;
            color: #555555;
          }
          .failBoxText3 {
            margin-top: 4px;
            font-size: 13px;
            color: #9C9C9C;
          }
        }
        .line {
          width: 1px;
          height: 35px;
          margin-left: 7px;
        }
        .containerItem {
          display: flex;
          align-items: center;
          .applyResultBoxIcon {
            width: 17px;
            height: 17px;
            margin-right: 24px;
          }
          .containerItemSuccessText {
            color: var(--color-primary);
            font-size: 18px;
            font-weight: bold;
          }
          .containerItemFailText {
            color: #939393;
            font-size: 18px;
          }
        }
      }
    }
  }

  .jufudefalutPageBox {
    background-image: url('~@imgs/jufu/result-backgroud.png') !important;
  }
}
.default {
  padding:48px 0px 0px 0px;
}
