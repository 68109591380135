@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.borrowMoney {
  display: flex;
  padding: 60px 16px 46px;
  flex-flow: column;

  .tabsBox {
    position: sticky;
    top: 0;
    z-index: 2;
    background: $background-color;
    flex: none;

    :global {
      .adm-tabs-header {
        border-bottom: none;
      }

      .adm-tabs-tab-wrapper-stretch {
        flex: none;
      }

      .adm-tabs-tab-wrapper {
        padding: 0 20px 0 0;
      }

      .adm-tabs-tab {
        padding: 16px 0;
        font-weight: bold;
        color: #595959;

        &.adm-tabs-tab-active {
          color: $color-black;
        }
      }
    }
  }

  .content {
    flex: 1;
  }

  :global {
    .adm-pull-to-refresh {
      min-height: calc(100vh - 40px - 32px);
    }
  }
}
