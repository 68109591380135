@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.applicationRecord {
  padding: 66px 16px 16px;

  .empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
