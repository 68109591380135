@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.downloadPage {
  padding: 20px 16px;
  min-height: 100%;
  background-color: $color-white;

  .top {
    // border-bottom: 1px solid $border-color;
    padding-bottom: 20px;

    .logoBox {
      @include flex-vc;

      .logo {
        flex: none;
        margin-right: 11px;
        width: 56px;
        height: 56px;
      }

      .name {
        margin-bottom: 6px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .size {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;

        @include flex-vc;
      }

      .tag {
        margin-left: 4px;
        border: 1px solid #52B447;
        border-radius: 2px;
        padding: 1px 2px;
        font-size: 10px;
        color: #52B447;
        line-height: 14px;
      }
    }

    .downloadBtn {
      margin: 16px 0;
      border-radius: 80px;
      height: 48px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: $color-white;
      background: $linear-gradient;
      line-height: 48px;

      &.disable {
        border: 1px solid $border-color;
        color: $placeholder-color;
        background: $background-color;
      }
    }

    .text {
      font-size: 14px;
      line-height: 22px;
      color: $text-gray-color;
    }
  }

  .installMethodBox {
    margin-top: 25px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;

      .right {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        .label {
          margin-right: 8px;
          color: $text-gray-color;
        }

        .dropdownBox {
          position: relative;
          display: flex;
          align-items: center;

          .dropdown {
            z-index: 1;
            border: 1px solid $border-color;
            border-radius: 2px;
            padding: 0  12px;
            width: 96px;
            height: 36px;
            background-color: $color-white;
            line-height: 36px;

            .arrows {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;

              /* 右箭头 */
              &::after {
                position: absolute;
                top: -8px;
                right: 16px;
                border: 1px solid $text-gray-color;
                border-color: $text-gray-color $text-gray-color transparent transparent;
                width: 8px;
                height: 8px;
                content: "";
                transform: rotate(135deg);
              }
            }
          }

          .selectBox {
            position: absolute;
            top: 110%;
            left: 0;
            display: none;
            border-radius: 2px;
            padding: 2px 0;
            width: 100%;
            background: $color-white;
            box-shadow: 0 9px 28px 8px rgb(0 0 0 / 5%), 0 6px 16px rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%);

            &.expansion {
              display: block;
            }

            .item {
              padding: 8px 12px;

              &.active {
                background-color: #FFF1F0;
              }
            }
          }
        }
      }
    }

    .stepBox {
      .row {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 16px;

        .left {
          flex: 1;
          display: flex;
          margin-right: 6px;
        }

        .num {
          flex: none;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          border-radius: 100%;
          width: 24px;
          height: 24px;
          font-size: 14px;
          font-weight: bold;
          color: $color-white;
          background: $color-primary;
        }

        .text {
          line-height: 24px;
        }

        .bold {
          font-weight: bold;
          color: $color-primary;
        }

        .right {
          flex: 1;
          display: flex;
        }

        .img {
          width: 100%;
        }
      }
    }
  }

  .pcTip {
    margin-top: 10vh;
    text-align: center;

    .icon.ignoreConvert {
      /* px-to-viewport-ignore-next */
      margin-bottom: 30px;

      /* px-to-viewport-ignore-next */
      font-size: 70px;
      color: #1890FF;
    }

    .text.ignoreConvert {
      font-size: 20px; /* px-to-viewport-ignore */
      line-height: 28px; /* px-to-viewport-ignore */
    }
  }

  .tips {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    text-align: right;
    background: rgba($color: #000000, $alpha: 70%);

    .arrows {
      margin-top: 10px;
      margin-right: 10px;
    }

    .tipsTitle {
      position: absolute;
      right: 0;
      width: 58%;
      font-size: 16px;
      text-align: left;
      color: $color-white;
    }
  }
}
