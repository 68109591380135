@import "@/styles/var.scss";
@import "@/styles/mixin.scss";
@font-face {
  font-family: 'Anek';
  src: url('./Anek Bangla Condensed Thin.ttf');
}

.fillInformation {
  padding: 48px 0 40px;
  min-height: 100vh;
  background-color: #fff;
  * {
    font-family: 'Anek';
  }
  .headerBox {
    width: 100%;
    .progressBar {
      height: 4px;
      border-radius: 0px 4px 4px 0px;
      background: linear-gradient(270deg, var(--color-primary) 0%, rgba(255, 4, 29, 0.00) 100%);
      position: relative;
      .progressPercentage {
        font-size: 12px;
        color: var(--color-primary);
        font-weight: 600;
        position: absolute;
        // right: -10px;
        bottom: -20px;
      }
    }
    .progressInfo {
      padding: 0 24px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .progressInfoLeft {
        .progressLimit {
          color: var(--color-primary);
          font-size: 24px;
          font-weight: 600;
        }
        .progressText {
          margin-top: 2px;
          color: #AAA;
          font-size: 12px;
        }
      }
      .progressInfoRight {
        display: flex;
        align-items: baseline;
        .currentStepNum {
          font-size: 24px;
          font-weight: 600;
          color: #000;
        }
        .totalStepNum {
          font-size: 12px;
          font-weight: 600;
          color: #000;
        }
      }
    }
  }
  .lastBox {
    margin-top: 8px;
    padding: 0 16px;
    .lastBoxItem {
      margin-bottom: 18px;
    }
    .formHeader {
      display: flex;
      .formHeaderIcon {
        margin-top: 3px;
        width: 15px;
        height: 16px;
      }
      .formHeaderRight {
        margin-left: 5px;
        .formHeaderTitle {
          font-size: 13px;
          font-weight: 600;
          color: #000;
        }
        .formHeaderSubTitle {
          margin-top: 2px;
          font-size: 10px;
          color: #AAA;
        }
      }
    }
    .formBox {
      margin-top: 8px;
      padding: 0 12px;
      background-color: #F5F6F8;
      border-radius: 8px;
      .line {
        width: 100%;
        border-bottom: var(--border-inner)
      }
      .content {
        margin-top: 6px;
      }
    }
  }
  .city {
    :global {
      .adm-list-item-content-prefix {
        width: 140px;
      }
    }
  }
  .btn {
    margin: 0 auto;
    border: none;
    padding: 0;
    width: 343px;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    background: $linear-gradient;
    line-height: 48px;
  }

  .selectBox {
    @include flex-sbc;

    padding: 10px 0 10px;
    // margin-bottom: 10px;

    & + .selectBox {
      border-top: 1px solid #ddd;
    }

    .left {
      font-size: 14px;
    }

    .right {
      display: flex;
      font-size: 14px;


      >span {
        @include flex-cc;

        border-radius: 4px;
        min-width: 58px;
        padding: 0 7px;
        font-size: 14px;
        height: 30px;
        background: #E0E0E0;

        & + span {
          margin-left: 11px;
        }

        &.active {
          color: $color-white;
          background: var(--linear-gradient) !important;
        }
      }
    }

    // &.payoffForm {
    //   .right > span { 
    //     background: #fff;
    //   }
    // }
  }

  .stepBoxFix {
    // position: fixed;
    // left: 20px;
    // top: 120px;
    margin-top: 5px;
    padding: 0 20px;
    font-size: 12px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  :global {
    .adm-input-element {
      font-size: 14px;
      text-align: right;
      color: #262626;
      background-color: #F5F6F8;
    }

    .adm-list-body {
      background-color: transparent;
    }

    .adm-list-card {
      margin: 0;
    }

    .adm-list-item {
      padding: 0;
    }

    .adm-list-item-content {
      padding-right: 0;
      background-color: #F5F6F8;
      border: none;
    }

    .adm-form-item-label .adm-form-item-required-asterisk {
      display: none;
    }

    input:-webkit-autofill {
      box-shadow: 0 0 0 1000px #F5F6F8 inset;
    }

    .adm-form-item-label {
      height: 20px;
      font-size: 14px;
      color: #000;
      background-color: transparent;
    }
    .adm-list-item-content-prefix {
      @include flex-vc;
    }

    .adm-list-item-content-arrow {
      transform: rotate(90deg);
      font-size: 14px;
    }

    .adm-form-item-feedback-error {
      text-align: right;
    }

    .adm-selector-item-active{
      background: linear-gradient(108.61deg, #FF7A86 3.46%, #FF1C2F 97.1%);
    }

    .adm-selector {
      display: flex;
      flex-direction: row-reverse; /* 从右向左排列 */
    }

    // .adm-swiper-track-inner {
    //   transition: none !important;
    // }
  }
  
}