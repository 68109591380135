@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.personalData {
  padding-top: 50px;

  .main {
    margin: 16px;
    border-radius: 8px;
    padding: 4px 16px;
    background-color: $color-white;

    .row {
      @include flex-sbc;

      border-bottom: 1px solid $line-color;
      padding: 16px 0;
      font-size: 14px;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
