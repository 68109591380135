@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.loadingPage {
  height: 100%;
  background-color: #fff;
  padding: 48px 38px ;
  .loadingImageBox{
    margin:32px 0px 48px;
    @include flex-cc;
    .loadingImage{
      height: 180px;
    }
  }
  .progress{
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .progressBar {
      width: 100%;
      height: 12px;
      border-radius: 15px 15px 15px 15px;
      background: #D9D9D9;
      position: relative;
      overflow: hidden;
      .progressLine{
        width: 100%;
        height: 12px;
        border-radius: 15px 15px 15px 15px;
        background: var(--color-primary);
        transition: transform 0.3s ease; /* 添加过渡效果 */
        transform: translate(-100%);
      }
    }
    .progressPercentage {
      position: absolute;
      width: 40px;
      font-size: 14px;
      color: var(--color-primary);
      font-weight: 400;
      line-height: 20px;
      top: -12px;
    }
  }
  .loadingText{
    text-align: center;
    margin-top: 32px;
    font-size: 16px;
    font-weight: 600;
    color:#262626;
  }
  .loadingProcess{
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .loadingProcessBox{
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 46px;
      height: 40px;
      width: 200px;
      background-color: #7D5BDB0D;
      .checkIcon{
        color: var(--color-primary);
      }
      .loadingIconBox{
        .loadingIcon{
          color:var(--color-primary);
        }
      }
      .text{
        margin-top: 2px;
        font-size: 16px;
        font-weight: 600;
        color: var(--color-primary);
      }
    }
  }
}
