@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.my {
  .header {
    padding: 26px 16px;
    height: 276px;
    background: linear-gradient(180.83deg, #FF3F4B -.62%, rgb(255 69 82 / 0%) 71%);
    filter: drop-shadow(0 4px 20px rgb(247 40 40 / 20%));

    .phoneBox {
      font-size: 20px;
      font-weight: bold;
      color: $color-white;

      @include flex-sbc;

      .circle {
        border-radius: 100%;
        width: 80px;
        height: 80px;
        font-size: 36px;
        background: linear-gradient(180.5deg, #FF0000 .43%, rgb(255 0 22 / 0%) 71.96%);

        @include flex-cc;
      }
    }
  }

  .listBox {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: -186px 16px 16px;
    border-radius: 8px;
    padding: 20px 16px 0;
    background-color: $color-white;

    .title {
      padding-bottom: 16px;
      font-size: 16px;
      font-weight: 600;
    }

    .icon {
      font-size: 20px;
    }

    .bold {
      font-size: 14px;
      font-weight: bold;
      color: $color-primary;
    }

    :global {
      .adm-list-item-content-prefix {
        height: 20px;
      }
    }
  }

  .logout {
    @include flex-cc;

    position: relative;
    z-index: 1;
    margin: 0 16px;
    border-radius: 8px;
    height: 44px;
    color: $text-gray-color;
    background-color: $color-white;

    &:active {
      opacity: .8;
    }
  }
}

.bodyClassName {
  border-radius: 10px;
}

.modalContent {
  .title {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .btnBox {
    display: flex;

    .btn {
      @include flex-cc;

      border-radius: 24px;
      height: 48px;
      font-weight: bold;
      color: $color-white;
      flex: 1;

      & + .btn {
        margin-left: 8px;
      }

      &::before {
        border-radius: 24px;
      }

      &:first-child {
        border: 1px solid $color-primary;
        color: $color-primary;
      }

      &:last-child {
        background: $linear-gradient;
      }
    }
  }
}
