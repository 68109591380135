@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.registerLoadingPage {
  height: 100%;
  .hengbeiLoading{
    height: 100%;
    @include flex-cc;
    .loadingBox{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img{
        height: 200px;
        width: 200px;
      }
      span{
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
