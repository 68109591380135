@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.fullPage {
  background-color: #fff;
  min-height: 100vh;
  padding-top: 50px;
  .title {
    font-size: 16px;
    color: #262626;
    text-align: center;
    padding: 16px 0;
  }
  .imgUploadInput {
    display: none;
  }
  .uploadBox {
    margin-top: 16px;
    .uploadItem {
      margin-bottom: 24px;
      .applyBoxImg {
        width: 220px;
        height: 130px;
        margin: 0 auto;
        display: block;
        object-fit: cover;
        border-radius: 6px;
      }
      .applyBoxText {
        margin-top: 8px;
        font-size: 14px;
        color: #000;
        text-align: center;
        .applyBoxTextActive {
          color: #FF2235;
        }
      }
    }
  }
  .ruleBox {
    .ruleBoxTitle {
      color: #262626;
      font-size: 12px;
      text-align: center;
    }
    .applyBoxImg {
      display: block;
      width: 343px;
      margin: 16px auto;
    }
  }
  .footerBox {
    padding: 0 16px 60px;
    .tipText {
      margin-top: 40px;
      font-size: 12px;
      color: #8C8C8C;
      text-align: center;
    }
    .footerBtn {
      margin-top: 16px;
      border-radius: 24px;
      height: 48px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: $color-white;
      line-height: 48px;
      background: $linear-gradient;
    }
  }
}
