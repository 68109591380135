@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.modalPage {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 50%);

  .main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY((-50%));
    width: 300px;

    .content {
      border-radius: 10px;
      padding: 20px;
      width: 300px;
      text-align: center;
      background-color: $color-white;
      box-shadow: 0 9px 28px 8px rgb(0 0 0 / 5%), 0 6px 16px rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%);

      .img {
        width: 107px;
        height: 87px;
        margin-bottom: 2px;
      }

      .title {
        // margin-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
        color: #181830;
      }
      .btnBox {
        display: flex;
        justify-content: space-between;
    
        .btn {
          border: none;
          border-radius: 80px;
          padding: 0;
          width: 48%;
          height: 36px;
          background: $linear-gradient;
          line-height: 36px;
          color: #fff;
          &.cancelBtn {
            border: 1px solid #FF4D4F;
            background: #fff;
            color: #FF4D4F;
          }
        }
      }
    }

    .close {
      margin: 20px auto 0;
      width: 28px;
      height: 28px;
      background-size: 100% 100%;
      background-image: url("~@imgs/information/close.png");
    }
  }

  .phoneBox {
    height: 50px;
    background: #f4f5f7;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin: 9px auto 0;
    .label {
      width: 170px;
      text-align: center;
      font-size: 16px;
    }
    :global {
      .adm-input-element {
        font-size: 16px;
        // font-weight: 600;
        line-height: 30px;
        &::placeholder {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }
}

.formBox {
  margin-top: 2px;
  margin-bottom: 20px;
  .inputBox {
    position: relative;
    margin-top: 12px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding-left: 16px;
    height: 44px;
    background: $color-white;
    line-height: 44px;
  
    :global {
      .adm-input-element {
        font-size: 14px;
        line-height: 44px;
      }
    }
  
    .code {
      position: absolute;
      top: 50%;
      right: 16px;
      font-size: 14px;
      // font-weight: 600;
      color: $color-primary;
      transform: translateY(-50%);
    }
  
    .codeText {
      opacity: .5;
    }
  }
  
  :global {
    .adm-list-item,
    .adm-list-item-content,
    .adm-list-item-content-main {
      padding: 0 !important;
    }
  
    .adm-list-default .adm-list-body {
      border: none;
    }
  
    .adm-list-item-content {
      border: none;
    }
  
    .adm-list-body,
    .adm-list-item {
      background-color: transparent;
    }
  
    .adm-form-item-feedback-error {
      padding-left: 16px;
      text-align: left;
    }
  }
}