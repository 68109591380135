.productItem {
  padding: 14px 0;
  width: 108px;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .productTitleBox {
    display: flex;
    align-items: center;
    .productLogo {
      width: 18px;
      height: 18px;
      border-radius: 3px;
    }
    .productName {
      margin-left: 8px;
      font-size: 13px;
    }
  }
  .productLimit {
    margin-top: 17px;
    font-size: 22px;
    font-weight: bold;
    color: #F8311F;
    &.hotProductLimit {
      margin-top: 14px;
    }
  }
  .productLimitDesc {
    color: #8C8C8C;
    font-size: 12px;
    transform: scale(.9);
  }
  .applyRateBox {
    margin-top: 7px;
    text-align: center;
    .applyRate {
      color: #8C8C8C;
      font-size: 12px;
      transform: scale(.9);
    }
    .applyRateBar {
      margin-top: 3px;
      width: 71px;
      height: 4px;
      border-radius: 5px;
      background-color: #E5E5E5;
      overflow: hidden;
      .applyRateBarActive {
        height: 100%;
        background-color: #F8311F;
      }
    }
  }
  .applyBtn {
    margin-top: 11px;
    width: 85px;
    height: 26px;
    line-height: 13px;
    background-color: #fff;
    border: 1px solid #FF0016;
    color: #FF0016;
    font-size: 12px;
  }
}
